import type { ThemeOptions } from '@mui/material/styles'

export const components: ThemeOptions['components'] = {
  MuiOutlinedInput: {
    styleOverrides: {
      root: () => ({
        paddingBottom: '0 !important',
        paddingTop: '0 !important',
      }),
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        alignContent: 'center',
        display: 'flex',
        ...(ownerState.size === 'small' && {
          height: '36px',
        }),
        ...(!ownerState.size && {
          height: '42px',
        }),
      }),
      input: ({ ownerState }) => ({
        ...(ownerState.size === 'small' && {
          paddingTop: '6.5px !important',
          paddingBottom: '6.5px !important',
        }),
        ...(ownerState.size === 'medium' && {
          paddingTop: '9.5px !important',
          paddingBottom: '9.5px !important',
        }),
        ...(!ownerState.size && {
          paddingTop: '9.5px !important',
          paddingBottom: '9.5px !important',
        }),
      }),
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        lineHeight: 1,
        overflow: 'initial !important',
        top: getInputLabelTopPosition(ownerState.shrink, ownerState.size),
      }),
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontSize: theme.typography.h2.fontSize,
      }),
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: `${theme.spacing(2.5)} ${theme.spacing(3)} !important`,
      }),
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(3),
        display: 'flex',
        justifyContent: 'space-between',
      }),
    },
  },
  MuiPopper: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& .MuiDataGrid-filterForm': {
          alignItems: 'flex-end',
        },
        '& .MuiDataGrid-filterFormValueInput': {
          height: 'auto',
        },
        '& .MuiTooltip-tooltip': {
          backgroundColor: '#384751',
          boxShadow: theme.shadows[3],
        },
      }),
    },
  },
  MuiTooltip: {
    defaultProps: {
      placement: 'top',
    },
  },
}

function getInputLabelTopPosition(inputShrink: boolean | undefined = false, inputSize: string | undefined = 'small') {
  if (inputShrink) {
    return '3px'
  }

  return inputSize === 'small' ? '2px' : '-2px'
}
