import '@aws-amplify/ui-react/styles.css'
import '@/css/main.css'
import '@/features/localization/init'

import { LicenseInfo } from '@mui/x-license'
import { withScope } from '@sentry/react'
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import { ThemeProvider } from '@/features/whitelabel/context/WhitelabelContext'

import App from './App'
import environment from './environment'
import AmplifySetupWrapper from './features/authentication/components/AmplifySetupWrapper'
import { AuthProvider } from './features/authentication/contexts/AuthContext'
import { FeatureToggleProvider } from './features/featureToggle/contexts/FeatureToggleContext'

const MUI_PRO_PLAN_LICENSE_KEY = environment.getMuiProLicenseKey()

// The configuration of QueryClient is following this post here https://lakur.tech/2022/12/16/generating-meaningful-issues-in-sentry-with-react-query-+-axios/
// It has some good suggestions in how to add a global handler for all calls made with the @tanstack/react-query library
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 0,
      refetchOnWindowFocus: false,
    },
  },
  mutationCache: new MutationCache({
    onError: (err, _variables, _context, mutation) => {
      withScope((scope) => {
        scope.setContext('mutation', {
          mutationId: mutation.mutationId,
          variables: mutation.state.variables,
        })
        if (mutation.options.mutationKey) {
          scope.setFingerprint(
            // Duplicate to prevent modification
            Array.from(mutation.options.mutationKey) as string[],
          )
        }
      })
    },
  }),
  queryCache: new QueryCache({
    onError: (err, query) => {
      withScope((scope) => {
        scope.setContext('query', { queryHash: query.queryHash })
        scope.setFingerprint([query.queryHash.replaceAll(/\d/g, '0')])
      })
    },
  }),
})

// Set the license for the MUI pro plan
if (MUI_PRO_PLAN_LICENSE_KEY) {
  LicenseInfo.setLicenseKey(MUI_PRO_PLAN_LICENSE_KEY)
}

createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AmplifySetupWrapper>
          <AuthProvider>
            <ThemeProvider>
              <FeatureToggleProvider>
                <App />
              </FeatureToggleProvider>
            </ThemeProvider>
          </AuthProvider>
        </AmplifySetupWrapper>
      </BrowserRouter>
    </QueryClientProvider>
  </StrictMode>,
)
