import { Box } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import PageHeader from '@/components/layouts/PageHeader'
import { useAlertContext } from '@/contexts/AlertContext'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { CustomerSettingsForm } from '@/features/customer/components/settings/CustomerSettingsForm'
import { updateCustomerSettings } from '@/features/customer/endpoints/customerSettings'
import { useCustomerQuery } from '@/features/customer/hooks/useCustomerQuery'
import {
  useCustomerSettingsQuery,
  useInvalidateCustomerSettingsQuery,
} from '@/features/customer/hooks/useCustomerSettingsQuery'
import type { CustomerSettingsPayload } from '@/features/customer/types/customer'

export const CustomerSettingsForCustomerUser = () => {
  const { loggedInUser } = useAuth()
  const { t } = useTranslation()
  const { pushAlert } = useAlertContext()

  const customerUuid = loggedInUser?.allowedRoIds?.[0] ?? ''
  const userRole = loggedInUser!.role!

  const { customer, isFetching } = useCustomerQuery(
    { uuid: customerUuid },
    {
      enabled: Boolean(customerUuid),
    },
  )
  const { customerSettings } = useCustomerSettingsQuery(
    {
      uuid: customerUuid,
    },
    { enabled: Boolean(customerUuid) && !isFetching },
  )
  const invalidateCustomerSettingsQuery = useInvalidateCustomerSettingsQuery()

  const updateSettings = useMutation({
    mutationFn: (variables: { data: CustomerSettingsPayload }) => {
      if (!customer?.uuid) {
        return Promise.reject(new Error(t('customer_settings.form.error.customer_without_uuid')))
      }

      return updateCustomerSettings(customer.uuid, {
        ...variables.data,
      })
    },
    onSuccess: async () => {
      await invalidateCustomerSettingsQuery({ uuid: customer!.uuid!, location: customer!.location })

      pushAlert({
        message: t('customer_settings.form.save_success'),
        severity: 'success',
      })
    },
    onError: () => {
      pushAlert({
        message: t('customer_settings.form.save_error'),
        severity: 'error',
      })
    },
  })

  function getPageContent() {
    if (!customer || isFetching) return null

    return (
      <Box mt={3} sx={{ width: '100%' }}>
        {customerSettings && (
          <CustomerSettingsForm
            defaultValues={customerSettings}
            isIbanNeeded={customer.isIbanNeeded ?? false}
            userRole={userRole}
            onSubmit={(data) => {
              updateSettings.mutate({ data })
            }}
          />
        )}
      </Box>
    )
  }

  return (
    <>
      <PageHeader pageTitle={t('component.page_header.settings')} />
      {getPageContent()}
    </>
  )
}
