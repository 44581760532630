import type { FeatureToggles } from '@/features/featureToggle/types/featureToggle'

export const FEATURE_TOGGLES: FeatureToggles = {
  toggles: [
    {
      name: 'TEST_TOGGLE_FOR_FRONTEND',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_CUSTOMERS_PAGE_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_PARTNERS_PAGE_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_BIDDING_PAGE_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_BIDDING_SUBMIT_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_REPORTS_PAGE_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_RESOURCES_PAGE_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEX_PORTAL_NORDICS_FCRD_DOWN_BIDDING_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEX_PORTAL_SWEDEN_MFRR_UP_BIDDING_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_MEASUREMENTS_PAGE_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_ACTIVATION_GROUPS_PAGE_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_DISTURBANCES_PAGE_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_INTEGRATIONS_PAGE_ENABLED',
      enabled: true,
    },
    {
      name: 'CUSTOMER_USER_LOGIN_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_EXAMPLE_FEATURE_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_BESS_DASHBOARD_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_CUSTOMER_POWER_METRICS_ENABLED',
      enabled: true,
    },
  ].map((feature) => ({
    ...feature,
    impressionData: false,
  })),
}
