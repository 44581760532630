import { Box, FormGroup, Grid, InputAdornment } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import TextFieldController from '@/components/inputs/TextFieldController'
import { USER_ROLES } from '@/constants/userRoles'
import MFASwitch from '@/features/user/components/form/MFASwitch'
import type { User } from '@/features/user/types/user'

export default function InformationSection() {
  const { t } = useTranslation()
  const { control, setValue, getValues, trigger } = useFormContext<User>()

  const role = getValues('role')

  function triggerPhoneValidation() {
    trigger('phone')
  }

  return (
    <FormGroup>
      <Grid container gap={3}>
        <Grid item md={5} sm={6} xs={12}>
          <TextFieldController label={t('user_form.form.given_name_label')} name="givenName" />
        </Grid>
        <Grid item md={5} sm={6} xs={12}>
          <TextFieldController label={t('user_form.form.family_name_label')} name="familyName" />
        </Grid>

        <Grid item md={12}>
          <Grid item md={5} sm={6} xs={12}>
            <TextFieldController required label={t('user_form.form.email_label')} name="email" />
          </Grid>
        </Grid>

        <Grid item md={5} sm={6} xs={12}>
          <Box>
            <TextFieldController
              InputProps={{
                startAdornment: <InputAdornment position="start">+</InputAdornment>,
              }}
              label={t('user_form.form.phone_label')}
              name="phone"
              onChange={(event) => {
                // users are only allowed to enter numbers
                const value = event.target.value.replace(/\D/g, '')

                setValue('phone', value)
              }}
            />
          </Box>
        </Grid>

        <Grid item md={5} sm={6} xs={12}>
          <Controller
            control={control}
            name="isMFAEnabled"
            render={({ field: { value, name, onChange }, fieldState: { invalid, error } }) => (
              <MFASwitch
                disabled={role === USER_ROLES.ADMINISTRATORS.value || role === USER_ROLES.PARTNER_ADMINISTRATORS.value}
                error={invalid}
                helperText={error?.message}
                isMFAEnabled={value ?? false}
                name={name}
                onChange={(_, checked) => {
                  onChange(checked)
                  triggerPhoneValidation()
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </FormGroup>
  )
}
