import { useQueries } from '@tanstack/react-query'
import type { DateTime } from 'luxon'

import { getBidInsights, getCapacityInsights, getForecastInsights } from '@/features/bidding/endpoints/insights'
import { useErrorHandler } from '@/features/bidding/hooks/useErrorHandler'
import type { Forecast } from '@/features/bidding/types/bid'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'
import { ptusBetweenDates } from '@/features/bidding/utils/date/ptusBetweenDates'

const GET_BID_INSIGHTS_API_ID = 'GET_BID_INSIGHTS'
const GET_CAPACITY_INSIGHTS_API_ID = 'GET_CAPACITY_INSIGHTS'
const GET_FORECAST_INSIGHTS_API_ID = 'GET_FORECAST_INSIGHTS'

export type InsightsData = {
  data: {
    actual: (number | null)[]
    offered: (number | null)[]
    forecast: (number | null)[]
    ptus: DateTime[]
  } | null
}

export type InsightsDataQueryParams = {
  activationGroupUuid: string
  fromDeliveryDay: MarketDate
  toDeliveryDay: MarketDate
}

export const useInsightsDataQuery = (params: InsightsDataQueryParams): InsightsData => {
  const { activationGroupUuid, fromDeliveryDay, toDeliveryDay } = params
  const queryResults = useQueries({
    queries: [
      {
        queryKey: [GET_BID_INSIGHTS_API_ID, params],
        queryFn: () => getBidInsights(activationGroupUuid, fromDeliveryDay, toDeliveryDay),
      },
      {
        queryKey: [GET_CAPACITY_INSIGHTS_API_ID, params],
        queryFn: () => getCapacityInsights(params.activationGroupUuid, fromDeliveryDay, toDeliveryDay),
      },
      {
        queryKey: [GET_FORECAST_INSIGHTS_API_ID, params],
        queryFn: () => getForecastInsights(params.activationGroupUuid, fromDeliveryDay, toDeliveryDay),
      },
    ],
  })

  useErrorHandler(queryResults.some((result) => result.isError))

  const firstPtu = fromDeliveryDay.getStartOfDay()
  const lastPtu = toDeliveryDay.getEndOfDay().minus({ hour: 1 })
  const ptus = ptusBetweenDates(firstPtu, lastPtu)

  const bids = (queryResults[0].data ?? []).flatMap((bid) => bid.offeredBid)
  const actualCapacities = queryResults[1].data ?? []
  const forecasts = (queryResults[2].data ?? []).flatMap((forecast: Forecast) => forecast.forecastPtus)

  return {
    data: {
      offered: ptus
        .map((ptu) => bids.find((bid) => bid.ptu.start.equals(ptu)))
        .map((bid) => bid?.volume.quantity ?? null),
      actual: ptus
        .map((ptu) => actualCapacities.find((actualCapacity) => actualCapacity.startDate.equals(ptu)))
        .map((actualCapacity) => actualCapacity?.scheduledMaintainedCapacityWatts ?? null),
      forecast: ptus
        .map((ptu) => forecasts.find((forecast) => forecast.ptu.start.equals(ptu)))
        .map((forecast) => forecast?.volume.quantity ?? null),
      ptus: ptus,
    },
  }
}
