import axios from 'axios'
import { DateTime } from 'luxon'
import { v4 as uuidV4 } from 'uuid'

import environment from '@/environment'
import type { ApiBaseline, ApiResponseBaseline } from '@/features/report/types/baseline'

export const GET_FORECAST_XY_BASELINE_API = 'GET_FORECAST_XY_BASELINE'
export const GET_LIVE_XY_BASELINE_API = 'GET_LIVE_XY_BASELINE'
export const GET_RETROSPECT_XY_BASELINE_API = 'GET_RETROSPECT_XY_BASELINE'

export async function getForecastXyBaseline(
  startTime: DateTime,
  endTime: DateTime,
  portfolio: string,
): Promise<ApiBaseline> {
  return getBaseline({ startTime, endTime, calculationMethod: 'XY', baselineType: 'FORECAST', portfolio })
}

export async function getLiveXyBaseline(
  startTime: DateTime,
  endTime: DateTime,
  portfolio: string,
): Promise<ApiBaseline> {
  return getBaseline({ startTime, endTime, calculationMethod: 'XY', baselineType: 'LIVE', portfolio })
}

export async function getRetrospectXyBaseline(
  startTime: DateTime,
  endTime: DateTime,
  portfolio: string,
): Promise<ApiBaseline> {
  return getBaseline({ startTime, endTime, calculationMethod: 'XY', baselineType: 'RETROSPECT', portfolio })
}

type GetBaselineProps = {
  startTime: DateTime
  endTime: DateTime
  calculationMethod: string
  baselineType: string
  portfolio: string
}

export async function getBaseline({
  startTime,
  endTime,
  calculationMethod,
  baselineType,
  portfolio,
}: GetBaselineProps): Promise<ApiBaseline> {
  const startTimeTimestamp = startTime.toMillis()
  const endTimeTimestamp = endTime.toMillis()
  const BASELINE_API_URL = `${environment.services.insightsManagerApiUrl}/baseline?start-time=${startTimeTimestamp}&end-time=${endTimeTimestamp}&calculation-method=${calculationMethod}&baseline-type=${baselineType}&portfolio=${portfolio}`

  const response = await axios.get<ApiResponseBaseline>(BASELINE_API_URL)
  const baselineData = response.data

  const timeData =
    baselineData?.frames?.[0].data?.[0].map((value) => {
      return DateTime.fromMillis(value)
    }) ?? []

  const baselineSeries =
    baselineData?.frames.map((frame) => {
      const currentController = frame.fields[1].labels?.[0]

      return {
        uuid: uuidV4(),
        name: currentController ? `${currentController.name}: ${currentController.value}` : '--',
        data: frame.data?.[1] ?? [],
      }
    }) ?? []

  return Promise.resolve({ timeData, baselineSeries })
}
