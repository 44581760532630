import ReactGA from 'react-ga4'

import environment from '@/environment'
import type { User } from '@/features/user/types/user'

export function initGoogleAnalytics() {
  const trackId = environment.googleAnalyticsTrackId

  ReactGA.gtag('set', 'user_properties', {
    environment: environment.environmentName,
  })

  if (trackId) {
    ReactGA.initialize(trackId)
  }
}

export const setGoogleAnalyticsUserDimension = (loggedInUser: User | null) => {
  ReactGA.gtag('set', 'user_properties', {
    role: loggedInUser?.role ?? '',
  })
}
