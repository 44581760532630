import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'

import PageHeader from '@/components/layouts/PageHeader'
import { DataGridSyncUrlManagerProvider } from '@/contexts/DataGridSyncUrlManagerContext'
import NavigateToError from '@/features/error/pages/NavigateToError'
import Partners from '@/features/partner/pages/Partners'

export default function PartnerRoutes() {
  const { t } = useTranslation()

  return (
    <Routes>
      <Route
        element={
          <DataGridSyncUrlManagerProvider>
            <PageHeader isRoot pageTitle={t('component.page_header.partners')} />

            <Partners />
          </DataGridSyncUrlManagerProvider>
        }
        path="/"
      />

      <Route element={<NavigateToError statusCode={404} />} path="*" />
    </Routes>
  )
}
