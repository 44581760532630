import { Box, Typography } from '@mui/material'
import { lightBlue } from '@mui/material/colors'
import Paper from '@mui/material/Paper'
import ReactECharts from 'echarts-for-react'
import { DateTime } from 'luxon'
import type { FC } from 'react'
import { useState } from 'react'

import WeekSelector from '@/features/bidding/components/capacity/offer/WeekSelector'
import { useInsightsDataQuery } from '@/features/bidding/hooks/useInsightsDataQuery'
import { convertToRoundedMw } from '@/features/bidding/utils/calculations/convertToRoundedMw'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'

type Props = {
  activationGroupUuid: string
  deliveryDay: MarketDate
}

const CapacityOfferChart: FC<Props> = ({ activationGroupUuid, deliveryDay }) => {
  const fromDeliveryDay = deliveryDay.minus({ days: 7 })
  const toDeliveryDay = deliveryDay.plus({ days: 1 })
  const [start, setStart] = useState(fromDeliveryDay)

  const { data } = useInsightsDataQuery({ activationGroupUuid, fromDeliveryDay: start, toDeliveryDay })

  if (!data) {
    return
  }

  const itemStyles = {
    actual: { borderColor: lightBlue[700], borderWidth: 1, color: lightBlue[700] },
    offered: { borderColor: lightBlue[300], borderWidth: 1, color: lightBlue[50] },
    forecast: { borderType: 'dashed', borderColor: lightBlue[300], borderWidth: 1, color: lightBlue[300] },
  }
  const xAxisValueFormat = 'dd LLL, yyyy - HH:mm'
  const xAxisLabelFormat = 'ccc, dd LLL'
  const isXAxisValueStartOfDay = (value: string) =>
    DateTime.fromFormat(value, xAxisValueFormat).equals(DateTime.fromFormat(value, xAxisValueFormat).startOf('day'))
  const option = {
    color: [lightBlue[700], lightBlue[300], lightBlue[300]],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
    },
    grid: {
      left: '5%',
      right: '5%',
      top: '15%',
      bottom: '5%',
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    legend: {
      data: [
        { name: 'Actual (MW)', icon: 'line', itemStyle: itemStyles.actual },
        {
          name: 'Offered (MW)',
          icon: 'roundRect',
          itemStyle: itemStyles.offered,
        },
        {
          name: 'Forecast (MW)',
          icon: 'line',
          itemStyle: itemStyles.forecast,
        },
      ],
      left: 16,
      top: 32,
      color: lightBlue[700],
      itemWidth: 24,
    },
    xAxis: [
      {
        type: 'category',
        axisTick: {
          alignWithLabel: true,
          interval: (_index: any, value: string) => isXAxisValueStartOfDay(value),
        },
        axisLabel: {
          interval: (_index: any, value: string) => isXAxisValueStartOfDay(value),
          formatter: (value: string) => DateTime.fromFormat(value, xAxisValueFormat).toFormat(xAxisLabelFormat),
          hideOverlap: true,
        },
        lineStyle: {
          color: '#00000061',
        },
        boundaryGap: false,
        // prettier-ignore
        data: data.ptus.map(ptu => ptu.toFormat(xAxisValueFormat)),
      },
    ],
    yAxis: [
      {
        type: 'value',
        position: 'left',
        alignTicks: true,
        boundaryGap: false,
        axisLine: {
          show: true,
          lineStyle: {
            color: lightBlue[700],
          },
        },
        axisLabel: {
          formatter: '{value}',
        },
        axisTick: {
          show: true,
        },
      },
    ],
    series: [
      {
        name: 'Actual (MW)',
        type: 'line',
        showSymbol: false,
        symbol: 'circle',
        symbolSize: 4,
        itemStyle: itemStyles.actual,
        lineStyle: {
          color: lightBlue[700],
          width: 1,
          type: 'solid',
        },
        data: data.actual.map((watts) => (watts != null ? convertToRoundedMw(watts) : null)),
      },
      {
        name: 'Offered (MW)',
        type: 'line',
        showSymbol: false,
        symbol: 'circle',
        symbolSize: 4,
        itemStyle: itemStyles.offered,
        lineStyle: {
          color: lightBlue[300],
          width: 1,
          type: 'solid',
        },
        areaStyle: {
          color: lightBlue[50],
        },
        data: data.offered.map((watts) => (watts != null ? convertToRoundedMw(watts) : null)),
      },
      {
        name: 'Forecast (MW)',
        type: 'line',
        showSymbol: false,
        symbol: 'circle',
        symbolSize: 4,
        itemStyle: itemStyles.forecast,
        lineStyle: {
          color: lightBlue[300],
          width: 1,
          type: 'dashed',
        },
        data: data.forecast.map((watts) => (watts != null ? convertToRoundedMw(watts) : null)),
      },
    ],
  }

  const onWeeksChange = (weeks: number) => {
    setStart(deliveryDay.minus({ days: weeks * 7 }))
  }

  return (
    <Paper style={{ padding: 16, marginTop: 16 }}>
      <Typography variant="h1">Bid forecast & actuals</Typography>
      <Box sx={{ pt: 3, pb: 0 }}>
        <WeekSelector initialWeeks={1} onWeeksChange={onWeeksChange}></WeekSelector>
      </Box>
      <ReactECharts data-testid="capacity-offer-chart" notMerge={true} option={option} style={{ height: 500 }} />
    </Paper>
  )
}

export default CapacityOfferChart
