import { IconCheck } from '@aws-amplify/ui-react/internal'
import { Box, Tab, Tabs } from '@mui/material'
import type { FC } from 'react'
import React, { useState } from 'react'

import TabPanel from '@/components/layouts/TabPanel'
import type { ActivationGroup } from '@/features/activationGroup/types'
import CapacityOfferChart from '@/features/bidding/components/capacity/offer/CapacityOfferChart'
import CreateBidForm from '@/features/bidding/components/capacity/offer/CreateBidForm'
import type { BidHistory } from '@/features/bidding/types/bidHistory'
import getBidMetaData from '@/features/bidding/utils/getBidMetaData'
import type { GroupedBidParams } from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'

type Props = {
  groupParams: GroupedBidParams
  bidActivationGroups: ActivationGroup[]
  bidHistories: BidHistory[]
}

const BidOfferTabs: FC<Props> = ({ groupParams, bidActivationGroups, bidHistories }) => {
  const [activeTab, setActiveTab] = useState(0)

  if (bidActivationGroups.length === 1) {
    return (
      <Box>
        <CapacityOfferChart activationGroupUuid={bidActivationGroups[0].uuid} deliveryDay={groupParams.deliveryDay} />
        <CreateBidForm bidMetaData={getBidMetaData(groupParams.deliveryDay, bidActivationGroups[0])} />
      </Box>
    )
  }

  return (
    <>
      <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          scrollButtons="auto"
          style={{ minHeight: 0 }}
          value={activeTab}
          variant="scrollable"
          onChange={(_, newValue) => setActiveTab(newValue)}
        >
          {bidActivationGroups.map((activationGroup, index) => (
            <Tab
              key={activationGroup.uuid}
              icon={getTabCompleteIcon(bidHistories, activationGroup)}
              iconPosition="start"
              label={activationGroup.code}
              style={{ minHeight: 0 }}
              value={index}
            />
          ))}
        </Tabs>
      </Box>

      {bidActivationGroups.map((activationGroup, index) => (
        <TabPanel key={activationGroup.uuid} index={index} value={activeTab}>
          <CapacityOfferChart activationGroupUuid={activationGroup.uuid} deliveryDay={groupParams.deliveryDay} />
          <CreateBidForm bidMetaData={getBidMetaData(groupParams.deliveryDay, activationGroup)} />
        </TabPanel>
      ))}
    </>
  )
}

const getTabCompleteIcon = (bidHistories: BidHistory[], activationGroup: ActivationGroup) =>
  bidHistories.find((history) => history.activationGroupUuid === activationGroup.uuid) ? <IconCheck /> : undefined

export default BidOfferTabs
