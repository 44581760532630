import type { GridColDef, GridFilterModel, GridRowSelectionModel } from '@mui/x-data-grid'
import { GridOverlay } from '@mui/x-data-grid'
import type { TFunction } from 'i18next'
import type { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import PastBidDateSelectorToolbar from '@/features/bidding/components/capacity/offer/PastBidDateSelectorToolbar'
import PastBidPtusSelectionToolbar from '@/features/bidding/components/capacity/offer/PastBidPtusSelectionToolbar'
import { MAX_PTUS_PER_PAGE, Status } from '@/features/bidding/constants'
import { useBidQuery } from '@/features/bidding/hooks/useBidQuery'
import type { BidMetaData, BidPtu } from '@/features/bidding/types/bid'
import { convertToRoundedMw } from '@/features/bidding/utils/calculations/convertToRoundedMw'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'
import { getFilterItemValue } from '@/utils/datagrid/filters'

type PastBidPtusDataGridProps = {
  bidMetaData: BidMetaData
  copyPtusToBid: (ptus: BidPtu[]) => void
}

const PastBidPtusDataGrid = ({ bidMetaData, copyPtusToBid }: Readonly<PastBidPtusDataGridProps>) => {
  const { t } = useTranslation()

  const [selectedPtus, setSelectedPtus] = useState<GridRowSelectionModel>([])
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: 'pastDeliveryDay',
        operator: 'is',
        value: bidMetaData.deliveryDay.minus({ days: 1 }),
      },
    ],
  })

  const pastBidDeliveryDay = getFilterItemValue<MarketDate>(filterModel, 'pastDeliveryDay')

  const { bid: pastBid, isFetching: isFetchingPastBid } = useBidQuery({
    date: pastBidDeliveryDay!,
    portfolioCode: bidMetaData.portfolio.code,
    marketProgram: bidMetaData.marketProgram,
  })

  const maxDate = bidMetaData.deliveryDay.minus({ days: 1 }).getStartOfDay()

  const onCopyClick = () => {
    copyPtusToBid(getSelectedPtus(selectedPtus as string[], pastBid))
    setSelectedPtus([])
  }

  return (
    <CustomDataGrid
      checkboxSelection
      hideFooter
      clickableRows={{ onRowSelectionModelChange: setSelectedPtus }}
      columns={getColumns(t)}
      filterMode="server"
      filterModel={filterModel}
      getRowHeight={() => 52}
      getRowId={(row) => row.ptu.start.toString()}
      initialState={{
        pagination: { paginationModel: { pageSize: MAX_PTUS_PER_PAGE, page: 0 } },
      }}
      isLoading={isFetchingPastBid}
      rowSelection={{ multiple: true, model: selectedPtus }}
      rows={(pastBid?.status !== Status.DRAFT ? pastBid?.offeredBid : []) ?? []}
      slotProps={{
        toolbar:
          selectedPtus.length > 0
            ? {
                ptusSelected: selectedPtus.length,
                onCopyClick: onCopyClick,
                onSelectionClear: () => setSelectedPtus([]),
              }
            : { maxDate },
      }}
      slots={{
        toolbar: selectedPtus.length > 0 ? PastBidPtusSelectionToolbar : PastBidDateSelectorToolbar,
        noRowsOverlay: () => <GridOverlay>{t('bidding.create_bid.bid_not_available')}</GridOverlay>,
      }}
      title={t('bidding.create_bid.previous_bid')}
      onFilterModelChange={setFilterModel}
    />
  )
}

const getColumns = (t: TFunction<'translation'>): GridColDef<BidPtu>[] => [
  {
    field: 'pastDeliveryDay',
    headerName: t('bidding.create_bid.header.ptu'),
    flex: 1,
    valueFormatter: (value: DateTime) => value.toFormat('HH:mm'),
    valueGetter: (_, bidPtu: BidPtu) => bidPtu.ptu.start,
  },
  {
    field: 'offeredWatts',
    headerName: t('bidding.create_bid.header.offered'),
    valueFormatter: (value: number) => `${value ? convertToRoundedMw(value) : '-'} MW`,
    valueGetter: (_, bidPtu: BidPtu) => bidPtu.volume.quantity,
    flex: 1,
  },
]

const getSelectedPtus = (selectedPtus: string[], pastBid) =>
  pastBid.offeredBid?.filter((ptu) => selectedPtus.includes(ptu.ptu.start.toString()))

export default PastBidPtusDataGrid
