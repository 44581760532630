import { Box } from '@mui/material'
import type { ReactNode } from 'react'

import BackNavigationButton from '@/components/layouts/BackNavigationButton'
import PageAppBar from '@/components/layouts/PageAppBar'
import PageTitle from '@/components/layouts/PageTitle'
import CustomBreadcrumbs from '@/components/navigation/CustomBreadcrumbs'
import type { Breadcrumb } from '@/types/breadcrumb'

type CommonProps = {
  appBarContent?: ReactNode
  pageTitle: string
}

// When page is root, breadcrumbItems must be undefined as we do not show breadcrumbs
type RootProps = {
  isRoot?: true
  breadcrumbItems?: never
}

// When page is not a root page, breadcrumbItems can be defined
type BranchProps = {
  isRoot?: false
  breadcrumbItems?: Breadcrumb[]
}

type PageHeaderProps = CommonProps & (RootProps | BranchProps)

function PageHeader({ breadcrumbItems = [], pageTitle, appBarContent, isRoot }: Readonly<PageHeaderProps>) {
  return (
    <>
      {!isRoot && <BackNavigationButton />}
      {!isRoot && (
        <Box sx={{ paddingBottom: 2 }}>
          <CustomBreadcrumbs items={breadcrumbItems} />
        </Box>
      )}

      <PageTitle pageTitle={pageTitle} />
      <PageAppBar appBarContent={appBarContent} />
    </>
  )
}

export default PageHeader
