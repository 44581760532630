import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'

import PageHeader from '@/components/layouts/PageHeader'
import { DataGridSyncUrlManagerProvider } from '@/contexts/DataGridSyncUrlManagerContext'
import { ProtectedRoute } from '@/features/authentication/components/ProtectedRoute'
import { Availability } from '@/features/customer/pages/Availability'
import { CompanyInfo } from '@/features/customer/pages/CompanyInfo'
import { CustomerDetailsRouteInformation } from '@/features/customer/pages/constants'
import { CustomerActivationDetails } from '@/features/customer/pages/CustomerActivationDetails'
import { CustomerActivations } from '@/features/customer/pages/CustomerActivations'
import { CustomerAvailability } from '@/features/customer/pages/CustomerAvailability'
import { CustomerContactInfo } from '@/features/customer/pages/CustomerContactInfo'
import CustomerDetails from '@/features/customer/pages/CustomerDetails'
import { CustomerRevenue } from '@/features/customer/pages/CustomerRevenue'
import Customers from '@/features/customer/pages/Customers'
import { CustomerSettings } from '@/features/customer/pages/CustomerSettings'
import { CustomerSettingsForCustomerUser } from '@/features/customer/pages/CustomerSettingsForCustomerUser'
import { PowerMeasurements } from '@/features/customer/pages/PowerMeasurements'
import { Revenue } from '@/features/customer/pages/Revenue'
import NavigateToError from '@/features/error/pages/NavigateToError'
import { useFeatureToggle } from '@/features/featureToggle/contexts/FeatureToggleContext'

export default function CustomersRoutes() {
  const { t } = useTranslation()
  const { isEnabled } = useFeatureToggle()

  return (
    <Routes>
      <Route element={<ProtectedRoute permission="customers" />}>
        <Route element={<Availability />} path={'/availability'} />
        <Route element={<CompanyInfo />} path="/company-info" />
        <Route element={<CustomerSettingsForCustomerUser />} path="/settings" />
        <Route element={<ProtectedRoute permission="revenues" />}>
          <Route element={<Revenue />} path="/revenue" />
        </Route>
        {isEnabled('FLEXPORTAL_CUSTOMER_POWER_METRICS_ENABLED') && (
          <Route element={<ProtectedRoute permission="powerMeasurements" />}>
            <Route element={<PowerMeasurements />} path="/power-measurements" />
          </Route>
        )}
      </Route>

      <Route element={<ProtectedRoute permission="customerDetails" />}>
        <Route
          element={
            <DataGridSyncUrlManagerProvider>
              <PageHeader isRoot pageTitle={t('component.page_header.customers')} />
              <Customers />
            </DataGridSyncUrlManagerProvider>
          }
          path="/"
        />
        <Route element={<CustomerDetails />} path="/:customerUuid">
          <Route index element={<CustomerContactInfo />} />
          <Route element={<CustomerContactInfo />} path={CustomerDetailsRouteInformation.CONTACT_INFO.routePath} />
          <Route element={<CustomerAvailability />} path={CustomerDetailsRouteInformation.AVAILABILITY.routePath} />
          <Route element={<CustomerActivations />} path={CustomerDetailsRouteInformation.ACTIVATIONS.routePath} />
          <Route
            element={<CustomerActivationDetails />}
            path={CustomerDetailsRouteInformation.ACTIVATION_DETAILS.routePath}
          />
          <Route element={<CustomerRevenue />} path={CustomerDetailsRouteInformation.REVENUE.routePath} />
          <Route element={<CustomerSettings />} path={CustomerDetailsRouteInformation.SETTINGS.routePath} />
        </Route>
      </Route>

      <Route element={<NavigateToError statusCode={404} />} path="*" />
    </Routes>
  )
}
